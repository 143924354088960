.container{
    background-color: black;
    color: white;
    text-align: center;
    padding: 10px 20px 40px 20px;
}

.container  a{
    text-decoration: none;
    color: white;
}

.innerWrapper{
    width: 100%;
    margin: 0 auto;
    max-width: 800px;
}