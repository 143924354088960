.container{
    position: relative;
    padding: 20px 0 40px 0;
    max-width: 800px;
    margin: 0 auto;
    text-align:center;
}

.container > h3{
    font-size: 34px;
    font-weight: 400;
}

.dashedBkg{
    width: 600px;
    z-index: -1;
    position: absolute;
    border-bottom: 2px dashed lightgray;
    top: 0;
    bottom: 50%;
    left: 0;
    right: 0;
    margin: auto;
}

.row{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
}

.row > article {
    width: 20%;
    text-align:center;
    background-color: white;
}

article > img {
    width: 100%;
}

@media screen and (max-width: 768px){
.row{
    flex-flow: row wrap;
}

.row > article {
    width: 60%;
}

.dashedBkg{
    display: none;
}
}