.wrapper{
    padding: 30px;
    position: relative;
    overflow: hidden;
}

.backgroundImg{
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
}

.backgroundImg > img {
    object-fit: cover;
}

header{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    font-size:  30px;
}

.container{
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 10px;
    color: white;
    background-color: #D26419;
    font-size: 14px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.row{
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.row.center{
    justify-content: center;
}

.row.center > div {
    margin-left: 2%;
}

.row.spaced{
    font-size: 16px;
    justify-content: space-between;
}



.marginRight{
    margin-right: 1%;
}

.label{
    width: 60%;
}

.itemWrapper{
    width: 30%;
    margin-left: 3%;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
}

.calculatedValue{
    color: white!important;
    background-color: #D26419!important;
    font-weight: 500;
}

.itemWrapper > input, .outputBox,.itemWrapper > select {
    padding: 10px;
    background-color: white;
    color: black;
    width: 70px;
    margin-left: 15px;
    text-align: center;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: default;
}

.itemWrapper > input {
    cursor: text;
}

select::-ms-expand {	display: none; }
select{
    -webkit-appearance: none;
    appearance: none;
}
@-moz-document url-prefix(){
	.ui-select{border: 1px solid #CCC; border-radius: 4px; box-sizing: border-box; position: relative; overflow: hidden;}
  .ui-select select { width: 110%; background-position: right 30px center !important; border: none !important;}
}


.short{
    width: 20%!important;
}

h1{
    margin: 0;
    text-align: center;
}

p{
    margin-top: 0;
}

button{
    padding: 20px 40px;
    background-color: black;
    outline: none;
    border: none;
    color: white;
    border-radius: 5px;
}

@media screen and (max-width: 768px){
    .itemWrapper{
        width: 100%;
        justify-content: center;
    }
    .marginRight{
        margin-right: 0;
    }

    .short{
        width: 70px!important;
    }
}